import MainWelcomePage from "@/components/Layout/MainWelcomePage";
import {useUser} from "@auth0/nextjs-auth0";
import Link from "next/link";
import {useRouter} from "next/router";
/* This example requires Tailwind CSS v2.0+ */
import {useEffect, useState} from "react";

const redirectTo = "/dashboard";
export default function Home() {

    const {user} = useUser();
    const [navigating, setNavigating] = useState(false);
    const router = useRouter();

    useEffect(() => {
        if (user) router.push("dashboard");
        setNavigating(false);
    }, [user, router]);


    return (
        <MainWelcomePage>
            <div style={{fontSize: "45px", lineHeight: "50px"}}
                 className="text-gray-900 text-shadow-md font-heading">
                <div
                    className="block text-white uppercase">Continuous real-time
                </div>
                <div
                    className="block text-primary uppercase">hydration monitoring
                </div>
            </div>
            <div
                className="my-3 mx-auto text-gray-300 text-lg">
                <ul className="text-left pl-2 leading-10">
                    <li className="list-disc text-shadow-md">Get real-time hydration alerts and
                        recommendations.
                    </li>
                    <li className="list-disc text-shadow-md">View continuous sweat measurements.</li>
                    <li className="list-disc text-shadow-md">Analyze all your data in the cloud.</li>
                </ul>
            </div>
            <div className="mt-5 max-w-md mx-auto flex justify-center mt-8 flex-grow w-full">
                <div className="mt-3 rounded-md shadow mt-0 ml-3">/
                    <Link
                        href={`/api/auth/login?returnTo=${router.query?.redirectTo || redirectTo}`}
                        passHref={true}>
                        <button onClick={() => setNavigating(true)}
                                className={`btn btn-lg btn-primary ${navigating && "loading"} font-normal normal-case`}>Sign
                            In
                        </button>
                    </Link>
                </div>
            </div>
        </MainWelcomePage>
    );
}
