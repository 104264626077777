import Link from "next/link";
import { PropsWithChildren } from "react";

type MainWelcomePageProps = {

}
export default function MainWelcomePage({children}: PropsWithChildren<MainWelcomePageProps>) {
    return (
        <div className="relative bg-black overflow-hidden w-[100vw] h-[100vh] min-w-[1100px]"
             style={{
                 // backgroundImage: "url(bg-image-home.jpg)",
                 // backgroundSize: "contain",
                 // backgroundRepeat: "no-repeat",
                 // backgroundPosition: "center"
             }}>
            <div className="bg-white flex items-center justify-center shadow-xl">
                <Link href="https://www.epicorebiosystems.com" passHref>
                    <img className="h-20 p-4 cursor-pointer"
                         src="img/epicore-logo.svg"
                         alt="Epicore Biosystems logo"/>
                </Link>
            </div>

            <div className="relative sm:pb-24 h-full shadow-inner-2xl pb-20" style={{
                backgroundImage: "url(img/pattern-custom.png)",
                backgroundSize: "50px 50px"
            }}>
                <main className="mx-auto max-w-4xl px-4 h-full flex items-center justify-center">
                    <div className="text-center flex flex-col">
                        {children}
                    </div>
                </main>
            </div>
        </div>
    );
}
